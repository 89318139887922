import { useCallback } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  StaticRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import baseLoadable from '@utils/loadable';
import isBot from 'isbot';
import * as Sentry from '@sentry/react';
// import isUndefined from 'lodash-es/isUndefined';

import Header from '../../components/Header';
import UserMessagesService from '../../components/UserMessages/UserMessagesService';
import Footer from '../Footer';
import PrivacyNoticeBar from '../../components/PrivacyNoticeBar';
import Preloader from '../../components/Preloader';
import ResetPassword from '../../components/ResetPassword';
import ScrollUpButton from '../../components/Buttons/ScrollUpButton';

import ChatbotWidget from '../../components/ChatbotComponents';

import withMetatag from '../../decorators/metadataDecorators/metatagsTitleDecorator';
import PiwikContext from '../../services/piwikContext';

// containers
import SwitchRouteContainer from '../SwitchRouteContainer/SwitchRouteContainer';

// constants for SEO pages
import * as SEO_PAGE_TYPES from '../../data/directory/topicTypes';
// Topic class types
import {
  COMMODITIES,
  CRYPTOCURRENCIES,
  PRIVATE_COMPANIES,
  PUBLIC_COMPANIES,
  SLUG_TO_TYPE_MAPPING,
  TYPE_TO_SLUG_MAPPING,
} from '../../data/directory/topic_classes/topicClassTypes';
// SEO page URL paths
import {
  DIRECTORIES_PATH,
  TOPIC_CLASS_PATH,
  SECTORS_PATH,
  LOCATIONS_PATH,
  LOCATIONS_PATH_ITEM,
  // PREMIUM_PUBLICATIONS_PATH,
  DCSC_SECTORS_PATH,
} from '../../data/directory/constants';
import { CHARTS, INSIDER_TRANSACTIONS } from '../../data/directory/topic_classes/tabPaths';
import locationsListMapping from '../../data/directory/locations/locationsListMapping';
import { RAILS_ENV } from '../../data/environment';
import { UK_PRIVATE_COMPANY_INSIGHTS_PATH } from '../../data/ukPrivateCompanyInsights';
import { filingsProviderCode } from '../../data/filing';

import configureStore from '../../store/store';

const isBrowser = typeof window !== 'undefined';

if (
  RAILS_ENV
  && RAILS_ENV !== 'development'
  && isBrowser
  && !isBot(window.navigator.userAgent)
) {
  Sentry.init({
    dsn: 'https://d2052caa28c6414980c516826e75a8e9@o496231.ingest.sentry.io/5570251',
    autoSessionTracking: true,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 0.1,
    environment: RAILS_ENV,
  });
}

const loadable = (func) => (
  baseLoadable(
    func,
    {
      fallback: (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
          <Preloader loading />
        </div>
      ),
    },
  )
);

const ProductsChatBot = loadable(() => import('../../components/ProductsComponents/ProductsPages/ProductsChatBot'));
const APIFinancialNewsPricing = loadable(() => import('../../components/ProductsComponents/ProductsPages/APIFinancialNewsPricing'));
const HistoricalDataPromo = loadable(() => import('../../components/ProductsComponents/ProductsPages/HistoricalDataPromo'));
const API_NLP = loadable(() => import('../../components/ProductsComponents/ProductsPages/API_NLP'));

const OnboardingPreferences = loadable(() => import('../../components/Onboarding/OnboardingPreferences'));
const OnboardingWatchlistInner = loadable(() => import('../../components/Onboarding/OnboardingWatchlistInner'));
const OnboardingRegistration = loadable(() => import('../../components/Onboarding/OnboardingRegisterPage/OnboardingAuthenticationWrapper'));

const NotificationPage = loadable(() => import('../PreferencesPages/NotificationPage'));
const AccountComponent = loadable(() => import('../PreferencesPages/AccountPage'));
const SubscriptionPage = loadable(() => import('../PreferencesPages/SubscriptionPage'));

const MainDirectory = loadable(() => import('../../components/SEO_pages/MainDirectory'));
const CryptoSummary = loadable(() => import('../../components/SEO_pages/CryptoCurrencies'));

const DCSCSectors = loadable(() => import('../../components/SEO_pages/DCSCSectors'));
const CryptoFullDetails = loadable(() => import('../../components/SEO_pages/CryptoCurrencies/Full_details'));
const TopicClasses = loadable(() => import('../../components/SEO_pages/TopicClasses'));
const TopicClassSlugs = loadable(() => import('../../components/SEO_pages/TopicClassSlugs'));
const TopicSlug = loadable(() => import('../../components/SEO_pages/TopicSlug'));
const Topic = loadable(() => import('../../components/SEO_pages/Topic'));
const LocationsSentiment = loadable(() => import('../../components/SEO_pages/LocationsSentiment'));
const TopicsSentimentAnalysis = loadable(() => import('../../components/SEO_pages/TopicsSentimentAnalysis'));
const UKPrivateCompanyInsightsHome = loadable(() => import('../../components/UKPrivateCompanyInsights/Home'));
const NatureofBusinessSectors = loadable(() => import('../../components/UKPrivateCompanyInsights/NatureofBusinessSectors'));
const NatureofBusinessSectorsSingle = loadable(() => import('../../components/UKPrivateCompanyInsights/NatureofBusinessSectorsSingle'));
const TopEquityFundraisers = loadable(() => import('../../components/UKPrivateCompanyInsights/TopEquityFundraisers'));
const TopInvestors = loadable(() => import('../../components/UKPrivateCompanyInsights/TopInvestors'));
const SingleInvestor = loadable(() => import('../../components/UKPrivateCompanyInsights/SingleInvestor'));
const TopMarketCapitalisation = loadable(() => import('../../components/UKPrivateCompanyInsights/TopMarketCapitalisation'));
const CompaniesHighestROI = loadable(() => import('../../components/UKPrivateCompanyInsights/CompaniesHighestROI'));
const SingleCompanie = loadable(() => import('../../components/UKPrivateCompanyInsights/SingleCompanie'));
const SingleChangesCompanyStatus = loadable(() => import('../../components/UKPrivateCompanyInsights/SingleChangesCompanyStatus'));
const SingleStatusForAllCompanies = loadable(() => import('../../components/UKPrivateCompanyInsights/SingleStatusForAllCompanies'));

const Filing = loadable(() => import('../../components/Filing/Home'));
const CountryFilings = loadable(() => import('../../components/Filing/CountryFilings'));
const ProviderFilings = loadable(() => import('../../components/Filing/ProviderFilings'));
const ProviderFilingsAll = loadable(() => import('../../components/Filing/ProviderFilingsAll'));
const SingleFiling = loadable(() => import('../../components/Filing/SingleFiling'));
const InvestorRelations = loadable(() => import('../../components/InvestorRelations'));
const InvestorRelationsSinglePage = loadable(() => import('../../components/SEO_pages/InvestorRelations/SinglePage'));
const ESGComponent = loadable(() => import('../../components/ESGComponent'));
const ESGSinglePage = loadable(() => import('../../components/SEO_pages/ESGComponent/SinglePage'));
const InsiderTransactionsPage = loadable(() => import('../../components/SEO_pages/InsiderTransactionsPage'));

const AcademiaLanding = loadable(() => import('../../components/LandingPages/AcademiaLanding'));
const BrokersComponent = loadable(() => import('../../components/LandingPages/BrokersLanding'));
const MeetupComponent = loadable(() => import('../../components/MeetupComponent'));
const AboutUsComponent = loadable(() => import('../../components/AboutUsComponent'));
const HelpCenterComponent = loadable(() => import('../../components/HelpCenterComponent'));
const Press = loadable(() => import('../../components/Press'));

const ChartsExpand = loadable(() => import('../../components/SEO_pages/ChartsExpand'));
const WatchlistPage = loadable(() => import('../WatchlistPage'));
const WatchlistManagePage = loadable(() => import('../WatchlistManagePage'));
const LearnMore = loadable(() => import('../LearnMoreLayout'));
const HomePage = loadable(() => import('../../components/HomePage'));
const Pricing = loadable(() => import('../Pricing'));
const SignIn = loadable(() => import('../SignIn'));
const CorporateLayout = loadable(() => import('../CorporateLayout/CorporateLayoutClient'));
const TrendingStartup = loadable(() => import('../TrendingLayout/TrendingLayoutClient'));
const SearchPage = loadable(() => import('../../components/SearchPage'));
const SingleStoryContainer = loadable(() => import('../SingleStoryContainer'));
const SimilarStoriesSinglePage = loadable(() => import('../../components/SimilarStoriesSinglePage'));
const ComingSoonMessage = loadable(() => import('../../components/Filing/ComingSoonMessage'));
const InvestingComponent = loadable(() => import('../../components/InvestingComponent'));
const Terms = loadable(() => import('../../components/Terms'));
const Privacy = loadable(() => import('../../components/PrivacyComponent'));
const EAPITerms = loadable(() => import('../../components/EAPITermsComponent'));
const BookmarksPage = loadable(() => import('../../components/BookmarksComponent'));

// const PremiumPublications = loadable(() => import('../../components/SEO_pages/PremiumPublications'));

// const PremiumPublicationsSinglePage = loadable(() => import('../../components/SEO_pages/PremiumPublications/SinglePage'));
const FinPromptHomePage = loadable(() => import('../../components/FinPromptProject/HomePage/index'));

const componentsCache = {};

const Router = isBrowser ? BrowserRouter : StaticRouter;

export default ({ railsContext, ...props }) => {
  // first create a context for <ServerRouter>, it's where we keep the
  // results of rendering for the second pass if necessary
  const { piwikEnabled, sourcesEnabled } = props;
  const ScrollToTop = useCallback(({ location, history }) => {
    if (typeof window === 'undefined') {
      return null;
    }

    const { pathname } = location;
    if (pathname !== '/' && pathname.endsWith('/')) {
      history.replace(pathname.slice(0, -1));
      return null;
    }

    if (pathname.startsWith('/news/directory/')) {
      const pathArr = pathname.split('/').filter((f) => f);
      if (
        (pathArr[2] === 'cryptocurrencies' && pathArr[3] && pathArr[3] !== 'summary' && pathArr[3] !== 'full_details' && pathArr[4])
        || (pathArr[2] === 'private-companies' && pathArr[3])
        || (pathArr[2] === 'stocks' && pathArr[3] && pathArr[4])
      ) {
        return null;
      }
    }
    if (pathname.startsWith('/watchlists') || pathname.startsWith('/products/api/financial-news/pricing-api')) {
      return null;
    }
    window.scrollTo(0, 0);
    return null;
  }, []);

  return (
    <Provider store={configureStore(props)}>
      <Router location={railsContext.location}>
        {/* to scroll page to top when route changes */}
        <Route component={ScrollToTop} />

        <ResetPassword />
        <PrivacyNoticeBar />
        <ScrollUpButton />

        <PiwikContext.Provider value={{ piwikEnabled, sourcesEnabled }}>
          <Header {...props} railsContext={railsContext} />
          <SwitchRouteContainer>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/learn-more" exact render={() => <LearnMore {...props} />} />
              <Route path="/sign_in" render={(routeProps) => <SignIn {...{ ...routeProps, ...props }} />} />
              <Route path="/financial-enterprise-solutions" exact render={(routeProps) => <CorporateLayout {...{ ...routeProps, ...props }} />} />
              <Route path="/products/chatbot" exact render={(routeProps) => <ProductsChatBot {...{ ...routeProps, ...props }} />} />
              <Redirect
                exact
                from="/products/api/financial-news/pricing-api"
                to="/products/api/financial-news/pricing-api/commercial"
              />
              <Route path="/products/api/financial-news/pricing-api/:plan?" exact render={(routeProps) => <APIFinancialNewsPricing {...{ ...routeProps, ...props }} />} />
              <Route path="/products/api/financial-news/pricing-historical" exact component={HistoricalDataPromo} />
              <Route path="/products/api/natural-language-processing" exact render={(routeProps) => <API_NLP {...{ ...routeProps, ...props }} />} />
              <Route
                path={DIRECTORIES_PATH}
                exact
                component={MainDirectory}
              />
              <Route path="/pricing" exact render={(routeProps) => <Pricing {...{ ...routeProps, ...props }} />} />
              <Route exact path={TOPIC_CLASS_PATH} component={TopicClasses} />
              <Route path="/news/onboarding/watchlist" render={(routeProps) => <OnboardingWatchlistInner {...{ ...routeProps, ...props }} />} />
              <Route path="/news/onboarding/preferences" render={(routeProps) => <OnboardingPreferences {...{ ...routeProps, ...props }} />} />
              <Route path="/news/onboarding/registration" render={(routeProps) => <OnboardingRegistration {...{ ...routeProps, ...props }} />} />
              <Route path="/watchlists/manage" exact render={(routeProps) => <WatchlistManagePage {...{ ...routeProps, ...props }} />} />
              <Route path="/watchlists/:page?/:sentiment?" render={(routeProps) => <WatchlistPage {...{ ...routeProps, ...props }} />} />
              <Route path="/preferences/notifications" render={(routeProps) => <NotificationPage {...{ ...routeProps, ...props }} />} />
              <Route path="/news/sentiment" exact component={TopicsSentimentAnalysis} />

              <Route
                path={UK_PRIVATE_COMPANY_INSIGHTS_PATH}
                exact
                render={(routeProps) => (
                  <UKPrivateCompanyInsightsHome {...{ ...routeProps, ...props }} />
                )}
              />
              <Route
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/nature-of-business-sectors`}
                exact
                render={(routeProps) => (
                  <NatureofBusinessSectors {...{ ...routeProps, ...props }} />
                )}
              />
              <Route
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/nature-of-business/:id`}
                render={(routeProps) => (
                  <NatureofBusinessSectorsSingle {...{ ...routeProps, ...props }} />
                )}
              />
              {/* <Route
                path="/news/directory/uk-private-companies-insights/top-equity-fundraisers"
                render={(routeProps) => (
                  <NatureofBusinessSectorsSingle
                    {...{ ...routeProps, ...props }}
                    isTopEquityFundraisers
                  />
                )}
              /> */}
              <Route
                exact
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/top-equity-fundraisers/date/:selected_date/:natureOfBusiness?`}
                render={(routeProps) => (
                  <TopEquityFundraisers
                    {...{ ...routeProps, ...props }}
                    isSinglePage
                  />
                )}
              />
              <Route
                exact
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/top-equity-fundraisers/:natureOfBusiness?`}
                render={(routeProps) => (
                  <TopEquityFundraisers {...{ ...routeProps, ...props }} />
                )}
              />
              <Route
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/top-investors/:natureOfBusiness?`}
                render={(routeProps) => (
                  <TopInvestors {...{ ...routeProps, ...props }} />
                )}
              />
              <Route
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/top-companies-by-market-capitalisation/:natureOfBusiness?`}
                render={(routeProps) => (
                  <TopMarketCapitalisation {...{ ...routeProps, ...props }} />
                )}
              />
              <Route
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/companies-with-highest-roi/:natureOfBusiness?`}
                render={(routeProps) => (
                  <CompaniesHighestROI {...{ ...routeProps, ...props }} />
                )}
              />
              <Route
                exact
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/entities/:name_slug/:page?`}
                render={(routeProps) => (
                  <SingleInvestor
                    {...{ ...routeProps, ...props }}
                    nameSlug={routeProps.match.params.name_slug}
                  />
                )}
              />
              <Route
                exact
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/companies/:company_number/:page?`}
                render={(routeProps) => (
                  <SingleCompanie
                    {...{ ...routeProps, ...props }}
                  />
                )}
              />
              <Route
                exact
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/changes-in-company-status`}
                render={(routeProps) => (
                  <SingleChangesCompanyStatus
                    {...{ ...routeProps, ...props }}
                  />
                )}
              />
              <Route
                exact
                path={`${UK_PRIVATE_COMPANY_INSIGHTS_PATH}/status-for-all-companies`}
                render={(routeProps) => (
                  <SingleStatusForAllCompanies
                    {...{ ...routeProps, ...props }}
                  />
                )}
              />

              <Redirect
                exact
                from="/news/trending"
                to="/news/:sentiment?"
              />
              <Route path="/news/:sentiment?" exact render={(routeProps) => <TrendingStartup {...{ ...routeProps, ...props }} />} />
              <Route path="/search" exact component={SearchPage} />
              <Route path="/segments/academia" component={AcademiaLanding} />
              <Route path="/meetup" component={MeetupComponent} />
              <Route path="/about" component={AboutUsComponent} />
              <Route path="/segments/brokers" component={BrokersComponent} />
              <Route
                path="/news/stories/:story_uuid"
                render={(routeProps) => <SingleStoryContainer {...{ ...routeProps, ...props }} />}
              />
              <Route
                path="/news/summary/:story_uuid"
                component={SimilarStoriesSinglePage}
              />
              <Route path="/bookmarks" component={BookmarksPage} />
              <Route
                path="/news/similar/:story_uuid"
                component={SimilarStoriesSinglePage}
              />
              <Route
                path="/preferences/account"
                render={(routeProps) => (
                  <AccountComponent {...{ ...routeProps, ...props }} />
                )}
              />
              <Route path="/preferences/subscription" component={(routeProps) => <SubscriptionPage {...{ ...routeProps, ...props }} />} />
              <Route
                exact
                path={`${SECTORS_PATH}/industries/subindustries/categories`}
                render={({ location: { pathname, search }, match }) => {
                  componentsCache[pathname] = (
                    componentsCache[pathname] || withMetatag(TopicClassSlugs, pathname)
                  );
                  const WithMetatag = componentsCache[pathname];

                  return (
                    <WithMetatag
                      slugType={SEO_PAGE_TYPES.CATEGORY_ONLY}
                      slugName={match.params.sector_slug}
                      queryString={search}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${SECTORS_PATH}/:sector_slug/:industry_slug/:sub_industry_slug/:startup_category_slug/:sentiment?`}
                render={({ location: { pathname }, match }) => {
                  componentsCache[pathname] = (
                    componentsCache[pathname] || TopicSlug
                  );
                  const WithMetatag = componentsCache[pathname];

                  return (
                    <WithMetatag
                      {...props}
                      slugType={SEO_PAGE_TYPES.CATEGORY}
                      topicSlugName={match.params.startup_category_slug}
                    />
                  );
                }}
              />
              {/* Legacy sectors redirections */}
              <Redirect
                from={SECTORS_PATH}
                to={`${SECTORS_PATH}/industries/subindustries/categories`}
              />
              {/* DCSC Sectors */}
              <Route
                exact
                path={DCSC_SECTORS_PATH}
                component={DCSCSectors}
              />
              <Route
                exact
                path={`${DCSC_SECTORS_PATH}/:levelType(level1|level2|level3|level4)/:sectorSlug/:page?/:sentiment?`}
                render={({ match: { params } }) => (
                  <Topic
                    user={props.user}
                    slugType={SEO_PAGE_TYPES.DCSC_SECTORS}
                    topicSlug={params.sectorSlug}
                    page={params.page}
                    piwikEnabled={piwikEnabled}
                    assetType={params.levelType}
                  />
                )}
              />
              {/* DCSC Sectors end */}
              <Route
                exact
                path={`${SECTORS_PATH}/:sector_slug/:industry_slug/:sub_industry_slug/:startup_category_slug/topic-class/:asset_class_slug`}
                render={({ match, location: { search, pathname } }) => {
                  componentsCache[pathname] = (
                    componentsCache[pathname] || withMetatag(TopicClassSlugs, pathname)
                  );
                  const WithMetatag = componentsCache[pathname];

                  return (
                    <WithMetatag
                      slugType={SEO_PAGE_TYPES.CATEGORY_TOPIC_CLASS}
                      sectorSlug={match.params.sector_slug}
                      industrySlug={match.params.industry_slug}
                      subIndustrySlug={match.params.sub_industry_slug}
                      startupCategorySlug={match.params.startup_category_slug}
                      slugName={match.params.asset_class_slug}
                      queryString={search}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              {/* For SEO location pages */}
              <Route
                exact
                path={`/directory/sentiment/${LOCATIONS_PATH_ITEM}`}
                render={(routeProps) => (
                  <LocationsSentiment
                    {...{ ...routeProps, ...props }}
                    piwikEnabled={piwikEnabled}
                  />
                )}
              />
              <Route
                exact
                path={LOCATIONS_PATH}
                render={({ location: { search, pathname } }) => {
                  const WithMetatag = withMetatag(TopicClassSlugs, pathname);

                  return (
                    <WithMetatag
                      user={props.user}
                      slugType={SEO_PAGE_TYPES.LOCATION}
                      slugName="Locations"
                      queryString={search}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${LOCATIONS_PATH}/:parentType/:slugName/:childType(subcontinents|countries|states|cities|areas|places)`}
                render={({ location: { search }, match: { params } }) => {
                  const { parentType, slugName, childType } = params;

                  return (
                    <TopicClassSlugs
                      slugType={locationsListMapping[childType]}
                      parentType={parentType}
                      childType={childType}
                      slugName={slugName}
                      queryString={search}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${LOCATIONS_PATH}/continent/:continentSlug/:page?/:sentiment?`}
                render={({ location: { pathname }, match: { params } }) => {
                  componentsCache[pathname] = (
                    componentsCache[pathname] || withMetatag(TopicSlug, pathname)
                  );
                  const WithMetatag = componentsCache[pathname];

                  return (
                    <WithMetatag
                      user={props.user}
                      slugType={SEO_PAGE_TYPES.CONTINENT}
                      topicSlugName={params.continentSlug}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${LOCATIONS_PATH}/subcontinent/:subContinentSlug/:page?/:sentiment?`}
                render={({ location: { pathname }, match: { params } }) => {
                  componentsCache[pathname] = (
                    componentsCache[pathname] || withMetatag(TopicSlug, pathname)
                  );
                  const WithMetatag = componentsCache[pathname];

                  return (
                    <WithMetatag
                      user={props.user}
                      slugType={SEO_PAGE_TYPES.SUBCONTINENT}
                      continentSlug={params.continentSlug}
                      topicSlugName={params.subContinentSlug}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${LOCATIONS_PATH}/country/:countrySlug/:page?/:sentiment?`}
                render={({ location: { pathname }, match: { params } }) => {
                  componentsCache[pathname] = (
                    componentsCache[pathname] || withMetatag(TopicSlug, pathname)
                  );
                  const WithMetatag = componentsCache[pathname];

                  return (
                    <WithMetatag
                      user={props.user}
                      slugType={SEO_PAGE_TYPES.COUNTRY}
                      continentSlug={params.continentSlug}
                      subContinentSlug={params.subContinentSlug}
                      topicSlugName={params.countrySlug}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${LOCATIONS_PATH}/state/:stateSlug/:page?/:sentiment?`}
                render={({ location: { pathname }, match: { params } }) => {
                  componentsCache[pathname] = (
                    componentsCache[pathname] || withMetatag(TopicSlug, pathname)
                  );
                  const WithMetatag = componentsCache[pathname];

                  return (
                    <WithMetatag
                      user={props.user}
                      slugType={SEO_PAGE_TYPES.STATE}
                      continentSlug={params.continentSlug}
                      subContinentSlug={params.subContinentSlug}
                      countrySlug={params.countrySlug}
                      topicSlugName={params.stateSlug}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${LOCATIONS_PATH}/city/:citySlug/:page?/:sentiment?`}
                render={({ location: { pathname }, match: { params } }) => {
                  componentsCache[pathname] = (
                    componentsCache[pathname] || withMetatag(TopicSlug, pathname)
                  );
                  const WithMetatag = componentsCache[pathname];

                  return (
                    <WithMetatag
                      user={props.user}
                      slugType={SEO_PAGE_TYPES.CITY}
                      continentSlug={params.continentSlug}
                      subContinentSlug={params.subContinentSlug}
                      countrySlug={params.countrySlug}
                      stateSlug={params.stateSlug}
                      topicSlugName={params.citySlug}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${LOCATIONS_PATH}/area/:areaSlug/:page?/:sentiment?`}
                render={({ location: { pathname }, match: { params } }) => {
                  componentsCache[pathname] = (
                    componentsCache[pathname] || withMetatag(TopicSlug, pathname)
                  );
                  const WithMetatag = componentsCache[pathname];

                  return (
                    <WithMetatag
                      user={props.user}
                      slugType={SEO_PAGE_TYPES.AREA}
                      continentSlug={params.continentSlug}
                      subContinentSlug={params.subContinentSlug}
                      countrySlug={params.countrySlug}
                      stateSlug={params.stateSlug}
                      citySlug={params.citySlug}
                      topicSlugName={params.areaSlug}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${LOCATIONS_PATH}/place/:placeSlug/:page?/:sentiment?`}
                render={({ match: { params } }) => (
                  <TopicSlug
                    user={props.user}
                    slugType={SEO_PAGE_TYPES.PLACE}
                    continentSlug={params.continentSlug}
                    subContinentSlug={params.subContinentSlug}
                    countrySlug={params.countrySlug}
                    stateSlug={params.stateSlug}
                    citySlug={params.citySlug}
                    topicSlugName={params.placeSlug}
                    piwikEnabled={piwikEnabled}
                  />
                )}
              />
              {/* SEO location pages end */}
              {/* Premium Publications pages */}
              {/* TODO: WEB-8187 Premium Publications will add again in future */}
              {/* <Route
                exact
                path={`${PREMIUM_PUBLICATIONS_PATH}/:id?/:sentiment?`}
                render={(routeProps) => {
                  const sentimentArr = ['all', 'very_positive', 'positive', 'neutral', 'negative', 'very_negative'];
                  if (sentimentArr.includes(routeProps.match.params.id)
                  || isUndefined(routeProps.match.params.id)) {
                    return (
                      <PremiumPublications {...{ ...routeProps, ...props }} />
                    );
                  }
                  return (
                    <PremiumPublicationsSinglePage {...{ ...routeProps, ...props }} />
                  );
                }}
              /> */}
              {/* Premium Publications pages end */}
              <Route
                exact
                path="/directory/investor-relations"
                component={InvestorRelations}
              />
              <Route
                exact
                path="/directory/investor-relations/:id"
                component={InvestorRelationsSinglePage}
              />
              <Route
                exact
                path="/directory/esg"
                component={ESGComponent}
              />
              <Route
                exact
                path="/directory/esg/:id"
                component={ESGSinglePage}
              />
              <Route
                exact
                path={`/directory/${INSIDER_TRANSACTIONS}`}
                component={InsiderTransactionsPage}
              />
              <Redirect
                exact
                from={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(CRYPTOCURRENCIES)}/summary/:page?`}
                to={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(CRYPTOCURRENCIES)}/news/:sentiment?`}
              />
              <Redirect
                exact
                from={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(CRYPTOCURRENCIES)}`}
                to={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(CRYPTOCURRENCIES)}/news/:sentiment?`}
              />
              <Route
                exact
                path={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(CRYPTOCURRENCIES)}/news/:sentiment?`}
                render={(routeProps) => <CryptoSummary {...{ ...routeProps, ...props }} />}
              />
              <Route
                exact
                path={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(CRYPTOCURRENCIES)}/full_details`}
                component={withMetatag(CryptoFullDetails, `${DIRECTORIES_PATH.slice(1)}/${TYPE_TO_SLUG_MAPPING.get(CRYPTOCURRENCIES)}/full_details`)}
              />
              <Route
                exact
                path={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(CRYPTOCURRENCIES)}/:topicSlug/${CHARTS}/expanded`}
                render={({ match }) => {
                  const { topicSlug } = match.params;

                  return (
                    <ChartsExpand
                      slugType={SEO_PAGE_TYPES.TOPIC_CLASS}
                      assetType={CRYPTOCURRENCIES}
                      topicClassSlug={TYPE_TO_SLUG_MAPPING.get(CRYPTOCURRENCIES)}
                      topicSlug={topicSlug}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(COMMODITIES)}/:topicSlug/${CHARTS}/expanded`}
                render={({ match }) => {
                  const { topicSlug } = match.params;

                  return (
                    <ChartsExpand
                      slugType={SEO_PAGE_TYPES.TOPIC_CLASS}
                      assetType={COMMODITIES}
                      topicClassSlug={TYPE_TO_SLUG_MAPPING.get(COMMODITIES)}
                      topicSlug={topicSlug}
                    />
                  );
                }}
              />
              <Redirect
                exact
                from={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PRIVATE_COMPANIES)}/summary/:page?`}
                to={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PRIVATE_COMPANIES)}/news/:sentiment?`}
              />
              <Redirect
                exact
                from={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PRIVATE_COMPANIES)}`}
                to={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PRIVATE_COMPANIES)}/news/:sentiment?`}
              />
              <Route
                exact
                path={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PRIVATE_COMPANIES)}/news/:sentiment?`}
                render={() => {
                  const pathname = `${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PRIVATE_COMPANIES)}`;
                  componentsCache[pathname] = (
                    componentsCache[pathname] || withMetatag(TopicSlug, pathname)
                  );
                  const WithMetatag = componentsCache[pathname];

                  return (
                    <WithMetatag
                      slugType={SEO_PAGE_TYPES.TOPIC_CLASS}
                      assetType={PRIVATE_COMPANIES}
                      topicSlug="summary"
                      topicSlugName="summary"
                      slugName={TYPE_TO_SLUG_MAPPING.get(PRIVATE_COMPANIES)}
                      user={props.user}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Redirect
                exact
                from={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PUBLIC_COMPANIES)}/summary/:page?`}
                to={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PUBLIC_COMPANIES)}/news/:sentiment?`}
              />

              <Redirect
                exact
                from={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PUBLIC_COMPANIES)}`}
                to={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PUBLIC_COMPANIES)}/news/:sentiment?`}
              />
              <Route
                exact
                path={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PUBLIC_COMPANIES)}/news/:sentiment?`}
                render={({ location: { pathname } }) => {
                  componentsCache[pathname] = (
                    componentsCache[pathname] || withMetatag(TopicSlug, pathname)
                  );
                  const WithMetatag = componentsCache[pathname];
                  return (
                    <WithMetatag
                      slugType={SEO_PAGE_TYPES.TOPIC_CLASS}
                      assetType={PUBLIC_COMPANIES}
                      topicSlug="summary"
                      topicSlugName="summary"
                      slugName={TYPE_TO_SLUG_MAPPING.get(PUBLIC_COMPANIES)}
                      user={props.user}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${DIRECTORIES_PATH}/${TYPE_TO_SLUG_MAPPING.get(PUBLIC_COMPANIES)}/:topicSlug/${CHARTS}/expanded`}
                render={({ match }) => {
                  const { topicSlug } = match.params;

                  return (
                    <ChartsExpand
                      slugType={SEO_PAGE_TYPES.TOPIC_CLASS}
                      assetType={PUBLIC_COMPANIES}
                      topicSlug={topicSlug}
                      topicSlugName={topicSlug}
                      slugName={TYPE_TO_SLUG_MAPPING.get(PUBLIC_COMPANIES)}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${DIRECTORIES_PATH}/:topicClassSlug`}
                render={(props) => {
                  const route = props.location.pathname;
                  componentsCache[route] = (
                    componentsCache[route] || withMetatag(TopicClassSlugs, route)
                  );
                  const WithMetatag = componentsCache[route];

                  return (
                    <WithMetatag
                      {...props}
                      slugType={SEO_PAGE_TYPES.TOPIC_CLASS}
                      slugName={props.match.params.topicClassSlug}
                      queryString={props.location.search}
                      piwikEnabled={piwikEnabled}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${DIRECTORIES_PATH}/:topicClassSlug/:topicSlug/:page?/:sentiment?`}
                render={({ match }) => (
                  <Topic
                    user={props.user}
                    page={match.params.page}
                    slugType={SEO_PAGE_TYPES.TOPIC_CLASS}
                    slugName={match.params.topicClassSlug}
                    topicSlug={match.params.topicSlug}
                    piwikEnabled={piwikEnabled}
                    assetType={SLUG_TO_TYPE_MAPPING.get(match.params.topicClassSlug)}
                  />
                )}
              />
              <Route
                exact
                path={`${DIRECTORIES_PATH}/:topicClassSlug/:topicSlug/${CHARTS}/expanded`}
                render={({ match }) => {
                  const { topicSlug, topicClassSlug } = match.params;

                  return (
                    <ChartsExpand
                      slugType={SEO_PAGE_TYPES.TOPIC_CLASS}
                      assetType={topicClassSlug}
                      topicClassSlug={topicClassSlug}
                      topicSlug={topicSlug}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`/directory/filings/:countryCode/${filingsProviderCode}/all`}
                component={ProviderFilingsAll}
              />
              <Route
                exact
                path={`/directory/filings/:countryCode/${filingsProviderCode}/:filingInfo`}
                component={SingleFiling}
              />
              <Route
                path={`/directory/filings/:countryCode/${filingsProviderCode}`}
                component={ProviderFilings}
              />
              <Route
                path="/directory/filings/:countryCode"
                component={CountryFilings}
              />
              <Route
                path="/directory/filings"
                component={Filing}
              />
              <Route
                exact
                path={`/directory/filings/:countryCode?/${filingsProviderCode}?`}
                component={ComingSoonMessage}
              />
              <Route
                exact
                path="/new"
                component={InvestingComponent}
              />
              <Route
                exact
                path="/help-videos/:tab/:video/:device"
                component={HelpCenterComponent}
              />
              <Redirect
                exact
                from="/help-videos/"
                to="/help-videos/getting-started/Get-Started-Within-A-Few-Minutes/desktop"
              />
              <Route
                exact
                path="/terms"
                component={Terms}
              />
              <Route
                exact
                path="/press"
                component={Press}
              />
              <Route
                exact
                path="/privacy"
                component={Privacy}
              />
              <Route
                exact
                path="/eapi_terms"
                component={EAPITerms}
              />
              <Route
                exact
                path="/finprompt"
                component={FinPromptHomePage}
              />
            </Switch>
            <ChatbotWidget {...props} />
          </SwitchRouteContainer>
          <UserMessagesService {...props} />
          <Footer {...props} />
        </PiwikContext.Provider>
      </Router>
    </Provider>
  );
};
