// core
import { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';

// utils
import loadable from '../../../utils/loadable';

// decorators
import withComponentName from '../../../decorators/withComponentName';
import withRouter from '../../../decorators/withRouter';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const ValuesModal = loadable(() => import('../../Modals/FooterModals/ValuesModal'));

export class FooterLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    autoBind(this);
    this.linkRef = createRef();
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  render() {
    const {
      isDivided,
      links,
      target,
      title,
      link,
      modal,
      useAnchor,
      trackUserClick,
      isRailsContext,
      location,
      footerContacts,
    } = this.props;
    const { showModal } = this.state;

    const footerLinkClass = cx('footer-link',
      { 'api-pricing-page': location.pathname.includes('/products/api/financial-news/pricing-api/') },
      { 'footer-contacts': footerContacts });

    return (
      <>
        {isDivided ? (
          <span className={Styles['divided-link']}>
            {links.map((item) => (
              isRailsContext || item.useAnchor ? (
                <a
                  key={item.link}
                  onClick={() => trackUserClick(item.title)}
                  href={item.link}
                  target={target || '_self'}
                >
                  {item.title}
                </a>
              ) : (
                <Link
                  key={item.link}
                  onClick={() => trackUserClick(item.title)}
                  to={item.link}
                  target={target || '_self'}
                >
                  {item.title}
                </Link>
              )
            ))}
          </span>
        ) : (
          <div className={Styles['footer-link-holder']} onClick={() => trackUserClick(title)} ref={this.linkRef}>
            {isRailsContext || useAnchor ? (
              <>
                {
                  title === 'Our Values' ? (
                    <span className={footerLinkClass} onClick={this.handleShow}>{title}</span>
                  ) : (
                    <a
                      className={footerLinkClass}
                      href={link}
                      target={target || '_self'}
                      onClick={this.handleShow}
                    >
                      {title}
                    </a>
                  )
                }
              </>
            ) : (
              <Link
                className={footerLinkClass}
                to={link}
                target={target || '_self'}
                onClick={this.handleShow}
              >
                {title}
              </Link>
            )}
          </div>
        )}
        {modal === 'values' && showModal && (
          <ValuesModal handleClose={this.handleClose} />
        )}
      </>
    );
  }
}

export default withComponentName(withRouter(FooterLink));
